import dayjs from 'dayjs';
import { IMovement, IMovementBody } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

interface IStockVariationsBody {
  products: number[];
  warehouse: number;
  date_start: string;
  date_end: string;
}

class MovementService extends BaseService<IMovement> {
  constructor() {
    super('movements');
  }
  canBulkDelete = true;

  getAggregated = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/aggregated_by_qty/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  moveProduct = async (body: IMovementBody) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/move_product/`, {
      ...body
    });
    return res.data;
  };

  stockVariations = async (body: IStockVariationsBody) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/stock_variations/`, { ...body });
    return res.data;
  };

  consumptionDetail = async (
    limit = 0,
    offset = 0,
    params: { timestamp__gte: string; timestamp__lte: string }
  ) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/consumption_detail/`, {
      params: {
        limit,
        offset,
        ...params
      }
    });

    const { timestamp__gte, timestamp__lte } = params;
    const to = dayjs(timestamp__gte, 'YYYY-MM-DD');
    const from = dayjs(timestamp__lte, 'YYYY-MM-DD');

    const monthRange = Math.ceil(from.diff(to, 'months', true));

    const data = res.data.results
      .filter((row) => !!row && JSON.stringify(row) !== '{}')
      .map((x) => {
        const record = {
          id: x.id,
          product: x.product,
          status: x.status,
          notes: x.notes,
          unit_price: x.unit_price,
          product_id: x.product_id,
          product_type: x.product_type,
          description: x.description,
          category: x.category,
          warehouse: x.warehouse,
          supplier: x.supplier,
          label: x.label,
          amount: 0,
          avg: 0,
          tot: 0
        };
        x?.quantities?.forEach((quantity) => {
          record[`m_${quantity.month - 1}__y_${quantity.year}`] = quantity.total_quantity;
          record.tot += quantity.total_quantity;
          if (isNaN(quantity.amount)) record.amount = quantity.amount;
          else record.amount += quantity.amount;
        });
        record.avg += record.tot / monthRange;
        record.avg = +record.avg.toFixed(2);
        return record;
      });

    return { results: data, count: res.data.count };
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const movementService = new MovementService();
