import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProductWarehouse, IPurchaseOrderRow, IStockQuantity } from 'interfaces';
import { useEffect, useState } from 'react';

interface InfoBoxPurchaseRequestCardProps {
  product_code: string | null;
  product_description?: string;
  stockQuantities?: IStockQuantity[];
  productWarehouse?: IProductWarehouse[];
  orderLines?: IPurchaseOrderRow[];
  consumes?: any[];
  unitPrice?: string;
}

export default function InfoBoxPurchaseRequestCard(params: InfoBoxPurchaseRequestCardProps) {
  const {
    product_code,
    product_description,
    stockQuantities,
    productWarehouse,
    orderLines,
    consumes,
    unitPrice
  } = params;
  const { t } = useTranslation();
  const translationPrefix = 'pages.purchase-requests.box-info';
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      product_code &&
      product_description &&
      stockQuantities &&
      productWarehouse &&
      orderLines &&
      consumes &&
      unitPrice
    ) {
      setLoading(false);
    }
  }, [
    product_code,
    product_description,
    stockQuantities,
    productWarehouse,
    orderLines,
    consumes,
    unitPrice
  ]);

  return (
    <>
      <Card style={{ width: '100%', height: '100%', padding: '2px' }}>
        <CardContent>
          {/* Default */}
          {(loading || !product_code) && (
            <div>
              {/* Title No Data */}
              <Typography variant="h5" component="div" color="primary">
                {t(`${translationPrefix}.details`)}
              </Typography>
              <br />
              {/* No data */}
              <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                <b>{t(`${translationPrefix}.no_data`)}</b>
              </Typography>
              <br />
            </div>
          )}
          {/* Box compilata */}
          {!loading && product_code && (
            <div>
              {/* Title */}
              <Typography variant="h5" component="div" color="primary">
                {t(`${translationPrefix}.details`)} <b>{product_code}</b>
              </Typography>
              <br />
              {/* Description */}
              <Typography component="div">
                {product_description}
                <br />
                {t(`${translationPrefix}.unit_price`)} {unitPrice}
                <br />
                {t(`${translationPrefix}.units_per_package`)}
                {productWarehouse?.[0]?.units_per_package ?? ''}
              </Typography>
              <br />
              {/* Thresholds */}
              <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                <b>{t(`${translationPrefix}.thresholds`)}</b>
                {productWarehouse?.map((pw) => (
                  <div key={pw.id}>
                    {t(`${translationPrefix}.thresholds-description`, {
                      restock: pw.restock_threshold,
                      under: pw.understock_threshold,
                      over: pw.overstock_threshold
                    })}
                  </div>
                ))}
              </Typography>
              <br />
              {/* StockQuantity */}
              <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                <b>{t(`${translationPrefix}.stock-quantity`)}</b>
                {stockQuantities?.map((stockQuantity) => (
                  <div key={stockQuantity.id}>
                    {t(`${translationPrefix}.stock-quantity-description`, {
                      batch: stockQuantity.product_batch,
                      expiration_date: stockQuantity.expiration_date,
                      quantity: stockQuantity.quantity
                    })}
                  </div>
                ))}
              </Typography>
              <br />
              {/* OrderLines */}
              <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                <b>{t(`${translationPrefix}.order-lines`)}</b>
                {orderLines?.map((orderLine) => (
                  <div key={orderLine.id}>
                    {t(`${translationPrefix}.order-lines-description`, {
                      code: orderLine.ref,
                      initial_qty: orderLine.initial_quantity,
                      remaining_qty: orderLine.remaining_quantity,
                      discounted: orderLine.discounted ? t(`global.yes`) : t(`global.no`)
                    })}
                  </div>
                ))}
              </Typography>
              <br />
              {/* Consume */}
              <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
                <b>{t(`${translationPrefix}.consumption`)}</b>
                {consumes?.map((consume) => (
                  <div key={consume.id}>
                    {t(`${translationPrefix}.consumption-description`, {
                      total: consume.tot,
                      unit_price: consume.unit_price,
                      amount: consume.amount,
                      avg: consume.avg,
                      supplier: consume.supplier
                    })}
                  </div>
                ))}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}
