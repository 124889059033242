import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IOption, ITransportDocument } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import { Autocomplete, Button, IconButton, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { useEffect, useState } from 'react';
import { supplierService, warehouseService } from 'services/api';

interface TransportDocumentFormParams {
  transport_document: ITransportDocument;
  onSubmit: any;
}

export const TransportDocumentForm = (params: TransportDocumentFormParams) => {
  const { transport_document, onSubmit } = params;
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);

  useEffect(() => {
    supplierService.getAllBaseInformation().then((res) => {
      setSuppliers(
        res.map((supplier) => {
          return {
            ...supplier,
            code: `${supplier.code} - ${supplier.company_name}`
          };
        })
      );
    });
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  return (
    <div>
      {transport_document && suppliers && (
        <ReactFinalForm
          onSubmit={onSubmit}
          edit={!!transport_document.id}
          hideControls={!!transport_document.id}
          formId="amountForm">
          <div className="w-full">
            {/* Prima riga: Supplier e Warehouse */}
            <div className="flex w-full gap-4 mb-4">
              <div className="flex-1">
                <IdentityField
                  name="supplier"
                  label={t(`global.supplier`)}
                  initialValue={transport_document.supplier}
                  initValue={suppliers.find((s) => s.id === transport_document.supplier)}
                  component={AutocompleteAdapter}
                  options={suppliers}
                  required
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="warehouse"
                  label={t(`global.warehouse`)}
                  initialValue={transport_document.warehouse}
                  initValue={warehouses.find((s) => s.id === transport_document.warehouse)}
                  component={AutocompleteAdapter}
                  options={warehouses}
                  type="text"
                />
              </div>
            </div>

            {/* Seconda riga: Ref, Date, Date Arrival, Closed */}
            <div className="flex w-full gap-4 mb-4 flex-wrap">
              <div className="flex-1">
                <IdentityField
                  name="ref"
                  label={t(`entities.ddt.ref`)}
                  initialValue={transport_document.ref}
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="date"
                  label={t(`entities.ddt.date`)}
                  component={DatePickerAdapter}
                  type="date"
                  initialValue={transport_document?.date ?? null}
                  noFuture={true}
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="date_arrival"
                  label={t(`entities.ddt.date_arrival`)}
                  component={DatePickerAdapter}
                  type="date"
                  initialValue={transport_document?.date_arrival ?? null}
                  noFuture={true}
                />
              </div>
              <div className="flex-1 flex items-center justify-center">
                <IdentityField
                  name="closed"
                  label={
                    transport_document?.closed ? t(`entities.ddt.closed`) : t(`entities.ddt.open`)
                  }
                  component={CheckboxAdapter}
                  type="checkbox"
                  initialValue={transport_document?.closed ?? false}
                  icon={<LockOpenOutlinedIcon />}
                  checkedIcon={<LockIcon />}
                  disabled
                />
              </div>
            </div>

            {/* Terza riga: Note */}
            <div className="flex w-full gap-4 mb-4">
              <div className="flex-1">
                <IdentityField
                  name="notes"
                  label={t(`entities.ddt.notes`)}
                  initialValue={transport_document.notes}
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
            </div>

            {/* Quarta riga: Pulsante Save */}
            <div className="flex justify-end">
              <Button variant="contained" type="submit" form="amountForm" color="primary">
                {t(`global.save`)}
              </Button>
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
};

export default TransportDocumentForm;
