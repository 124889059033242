import { useCallback, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { stockQuantityService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import {
  DataGridPremiumProps,
  GridColDef,
  GridColumns,
  GridRenderCellParams
} from '@mui/x-data-grid-premium';
import { filterOperators } from '../../../../constants';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { IsTenantCoordinatorOrAdmin } from 'components/helpers/IsTenantCoordinator';
import DataGridCell from 'components/common/DataGridCell';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import StockQuantitiesDetail from '../detail/StockQuantitiesDetail';
import productStatusCell from 'components/common/enhanced/cells/product-status-cell';
import listCell from 'components/common/enhanced/cells/list-cell';

export const StockQuantities = () => {
  const translationPrefix = 'pages.stock_quantity.list';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const aggregatedColumns: GridColumns = [
    {
      field: 'product',
      valueGetter: (params) => params.row.product__code,
      headerName: t(`global.product`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      filterable: false,
      sortable: false,
      width: 100,
      valueFormatter: ({ value }) => t(`entities.product.status_options.${value}`),
      renderCell: productStatusCell(t)
    },
    {
      field: 'product_type',
      valueGetter: (params) => params.row.product_type,
      headerName: t(`global.product_type`),
      width: 150,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'product__notes',
      headerName: t(`global.notes`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'unit_price',
      type: 'string',
      headerName: t(`entities.movement.unit_price`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <IsTenantCoordinatorOrAdmin>
          <DataGridCell content={currencyFormatter(params.value)} />;
        </IsTenantCoordinatorOrAdmin>
      )
    },
    {
      field: 'total_price',
      type: 'string',
      headerName: t(`global.total_price`),
      filterable: false,
      sortable: false,
      width: 100,
      valueGetter: (params) =>
        isNaN(params.row.unit_price)
          ? params.row.unit_price
          : params.row.unit_price * params.row.total_quantity,
      renderCell: (params: GridRenderCellParams<string>) => (
        <IsTenantCoordinatorOrAdmin>
          <DataGridCell content={currencyFormatter(params.value)} />;
        </IsTenantCoordinatorOrAdmin>
      )
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 300,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 250,
      filterOperators,
      valueGetter: (params) => params.row.warehouse__code,
      renderCell: baseCell
    },
    {
      field: 'locations',
      headerName: t(`global.location`),
      width: 250,
      filterOperators,
      renderCell: listCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'total_quantity',
      headerName: t(`entities.stock_quantity.total_quantity`),
      width: 150,
      type: 'number',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const understock = params.row.understock;
        const overstock = params.row.overstock;

        return (
          <div>
            {params.value}
            {understock !== null && params.value < understock && (
              <Tooltip title={t('alerts.understock', { value: understock })} placement="top">
                <TrendingDownIcon color="error" />
              </Tooltip>
            )}
            {overstock !== null && params.value > overstock && (
              <Tooltip title={t('alerts.overstock', { value: overstock })} placement="top">
                <TrendingUpIcon color="warning" />
              </Tooltip>
            )}
          </div>
        );
      }
    }
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: t(`global.actions`),
    //   getActions: (params: GridRowParams) => {
    //     const stockQuantityRow = params.row;
    //     const actions = [
    //       <GridActionsCellItem
    //         key={1}
    //         label="Dettaglio"
    //         icon={<ManageSearchIcon />}
    //         onClick={() =>
    //           movementsDetail({
    //             product: stockQuantityRow.product__code,
    //             warehouse: stockQuantityRow.warehouse__code
    //           })
    //         }
    //       />
    //     ];
    //     return actions;
    //   },
    //   filterable: false
    // }
  ];

  const columns: GridColDef[] = StockQuantitiesColumns(true);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <StockQuantitiesDetail row={{ ...row }} columns={columns} />, [columns]);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <ButtonGroup size="small">
            <NavLink to="past">
              <Button>{t(`${translationPrefix}.past`)}</Button>
            </NavLink>
            <NavLink to="all">
              <Button>{t(`${translationPrefix}.all_quantities`)}</Button>
            </NavLink>
          </ButtonGroup>
        }>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={stockQuantityService}
          getMethod={stockQuantityService.getTotalsByProduct}
          refresh={datagridRefresh}
          getDetailPanelContent={getDetailPanelContent}
        />
      </TenantPage>
    </>
  );
};

export default StockQuantities;
