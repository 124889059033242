import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography
} from '@mui/material';
import { IsTenantCoordinator } from 'components/helpers/IsTenantCoordinator';
import { PurchaseRequestStatus } from '../../../../constants';
import { Button } from '@mui/material';
import {
  contractLineService,
  movementService,
  orderLineService,
  productService,
  purchaseRequestService,
  purchaseRequestLineService,
  productWarehouseService,
  stockQuantityService
} from 'services/api';
import {
  IMovement,
  IOption,
  IProduct,
  IProductWarehouse,
  IPurchaseOrderRow,
  IPurchaseRequest,
  IPurchaseRequestRow,
  IStockQuantity,
  ISupplier,
  IWarehouse
} from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomizedSteppers from 'components/common/RequestStatus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import TenantPage from 'components/common/TenantPage';
import PurchaseRequestLineForm from '../PurchaseRequestLineForm';
import { GridCellEditCommitParams, GridColumns, itIT, MuiEvent } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';
import { PurchaseLinesColumns } from 'components/common/enhanced/common-headers/request-lines';
import dayjs from 'dayjs';
import toast from 'features/toast';
import ExportButtonToolbar from '../ExportButtonToolbar';
import InfoBoxPurchaseRequestCard from '../InfoBoxPurchaseRequestCard';

export const PurchaseRequest = () => {
  const { t } = useTranslation();
  const { purchaseRequestId } = useParams();
  const navigate = useNavigate();

  const toastTranlationPrefix = 'pages.purchase-requests.toast';
  let translationPrefix = 'pages.purchase-requests';
  if (purchaseRequestId) {
    translationPrefix += '.view';
  } else {
    translationPrefix += '.new';
  }

  const { enqueueSnackbar } = useSnackbar();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [requestPreview, setRequestPreview] = useState();

  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
  };
  const [productToAdd, setProductToAdd] = useState<any>(null);
  const [contractToAdd, setContractToAdd] = useState<any>(null);
  const [warehouseToAdd, setWarehouseToAdd] = useState<any>(null);
  const [maxUnits, setMaxUnits] = useState<number | null>(null);
  const [maxDiscountedUnits, setMaxDiscountedUnits] = useState<number | null>(null);

  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [contractOptions, setContractOptions] = useState<IOption[]>([]);
  const [warehouseOptions, setWarehouseOptions] = useState<IOption[]>([]);

  const [purchaseRequest, setPurchaseRequest] = useState<IPurchaseRequest>({
    lines: [],
    id: null,
    status: null,
    created_by: 0
  });
  const [initialRequest, setInitialRequest] = useState<IPurchaseRequest>(purchaseRequest);

  // Mostra info box dopo aver selezionato il magazzino ed il prodotto
  const [visibleProduct, setVisibleProduct] = useState<{
    code: string;
    description?: string;
    stockQuantities?: IStockQuantity[];
    productWarehouse?: IProductWarehouse[];
    orderLines?: IPurchaseOrderRow[];
    consume?: any[];
    unitPrice?: string;
  } | null>(null);

  const [unitPriceInfo, setUnitPriceInfo] = useState<string>(null);
  const [unitsPerPackage, setUnitsPerPackage] = useState<number>(0);

  const [opendConfirmationDialog, setOpendConfirmationDialog] = useState(false);
  const [confirmationValues, setConfirmationValues] = useState(null);

  // ------------------- USE EFFECT -------------------

  // Constructor
  useEffect(() => {
    productService.getAllBaseInformation({ status: 'ACTIVE' }).then(setProductOptions);
    if (purchaseRequestId) {
      purchaseRequestService.get(+purchaseRequestId).then((pr) => {
        setPurchaseRequest(pr);
        setInitialRequest(pr);
      });
    }
  }, []);

  useEffect(() => {
    if (productToAdd) {
      setContractToAdd(null);
      setContractOptions([]);
      contractLineService
        .getAllBaseInformation({ product_id: productToAdd.id, active: true, valid: true })
        .then(setContractOptions);
    } else {
      setContractToAdd(null);
      setContractOptions([]);
      setWarehouseToAdd(null);
      setWarehouseOptions([]);
    }
  }, [productToAdd]);

  useEffect(() => {
    if (contractToAdd?.id) {
      contractLineService.getSummary(contractToAdd.id).then((res) => {
        setMaxUnits(res.available_units_not_blocked);
        setMaxDiscountedUnits(res.available_discounted_units_not_blocked);
        setWarehouseOptions(res.warehouses);
        // BOX INFO - Get the unit price (1)
        setUnitPriceInfo(res.unit_price);
      });
    } else {
      setMaxUnits(null);
      setMaxDiscountedUnits(null);
      setWarehouseToAdd(null);
      setWarehouseOptions([]);
    }
  }, [contractToAdd]);

  useEffect(() => {
    if (warehouseToAdd && productToAdd && contractToAdd) {
      const limit = 20;
      const offset = 0;

      // Fetch product warehouse information
      const fetchProductWarehouseInfo = productWarehouseService.getAllPaginated(limit, offset, {
        product_id: productToAdd.id,
        warehouse_id: warehouseToAdd.id
      });

      // Fetch stock quantities information
      const fetchStockQuantitiesInfo = stockQuantityService.getAllPaginated(limit * limit, offset, {
        product__id: productToAdd.id,
        warehouse__id: warehouseToAdd.id
      });

      // Fetch product order lines information
      const fetchProductOrderLinesInfo = orderLineService.getAllPaginated(limit, offset, {
        product_id: productToAdd.id,
        warehouse_id: warehouseToAdd.id,
        status: 'PENDING'
      });

      // Fetch product consumption information for the past year
      const paramsConsumption = {
        timestamp__gte: dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
        timestamp__lte: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        product__id: productToAdd.id,
        warehouse__id: warehouseToAdd.id
      };
      const fetchProductConsumeInfo = movementService.consumptionDetail(
        limit,
        offset,
        paramsConsumption
      );

      // Execute all fetch requests concurrently and update the state with the results
      Promise.all([
        fetchProductWarehouseInfo,
        fetchStockQuantitiesInfo,
        fetchProductOrderLinesInfo,
        fetchProductConsumeInfo
      ]).then(
        ([productWarehouseRes, stockQuantitiesRes, productOrderLinesRes, productConsumeRes]) => {
          setVisibleProduct({
            code: productToAdd.code,
            description: productToAdd.description.replace(
              //correzione descrizion IOption
              productToAdd.code + ' - ',
              ''
            ),
            stockQuantities: stockQuantitiesRes.results,
            productWarehouse: productWarehouseRes.results,
            orderLines: productOrderLinesRes.results,
            consume: productConsumeRes.results,
            unitPrice: unitPriceInfo
          });
          setUnitsPerPackage(parseInt(productWarehouseRes?.results[0]?.units_per_package) || 0);
        }
      );
    } else {
      // Reset visible product information if any of the dependencies change
      setVisibleProduct(null);
    }
  }, [warehouseToAdd, productToAdd, contractToAdd, unitPriceInfo]);

  // Edit Purchase req methods
  const reviewRequest = () => {
    if (purchaseRequest.id) {
      purchaseRequestService
        .preApproveRequest({
          id: purchaseRequest?.id,
          lines: purchaseRequest.lines.map(mapLine)
        })
        .then(() => {
          enqueueSnackbar(t(`${toastTranlationPrefix}.pre-approved-successfully`), {
            variant: 'success'
          });
          location.reload();
        })
        .catch(() => {
          enqueueSnackbar(t(`${toastTranlationPrefix}.no-permission`), { variant: 'error' });
        });
    }
  };

  const fulfillRequest = (sendEmail = false) => {
    if (purchaseRequestId) {
      if (purchaseRequest.lines.some((e) => e.status === 1)) {
        setOpenPreviewDialog(false);
        toast.error(t(`${toastTranlationPrefix}.pending-lines`));
        return;
      }
      purchaseRequestService
        .approveRequest(
          {
            id: +purchaseRequestId,
            lines: purchaseRequest.lines.map(mapLine)
          },
          sendEmail
        )
        .then(() => {
          location.reload();
          enqueueSnackbar(t(`${toastTranlationPrefix}.order-sent-successfully`), {
            variant: 'success'
          });
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  /**
   * Save the purchase request.
   * If the purchase request is new, it will be added.
   * If the purchase request is existing, it will be updated.
   */
  const saveRequest = () => {
    // Check if the purchase request is valid
    if (!purchaseRequest) {
      toast.error(t(`${toastTranlationPrefix}.invalid-request`));
      return;
    }

    // Map the lines in the purchase request
    const lines = purchaseRequest.lines.map(mapLine);

    // Create the request object
    const request = {
      ...purchaseRequest,
      lines
    };

    // Determine the service promise
    const servicePromise = purchaseRequest.id
      ? // Update the purchase request
        purchaseRequestService.update(purchaseRequest.id, request)
      : // Add the purchase request
        purchaseRequestService.add(request);

    // Call the service promise
    servicePromise
      .then((res) => {
        if (res?.id) {
          // Update the purchase request state
          setPurchaseRequest(res);

          // Update the initial request state
          setInitialRequest(res);

          // Determine the message based on the purchase request id
          const message = purchaseRequest?.id
            ? t(`${toastTranlationPrefix}.line-successfully-updated`)
            : t(`${toastTranlationPrefix}.line-successfully-added`);

          // Show the success snackbar
          enqueueSnackbar(message, { variant: 'success' });

          // Navigate to the view page of the purchase request
          navigate(`../view/${res.id}`);
        }
      })
      .catch((e) => {
        // Show the error toast
        toast.error(e.message);
      });
  };

  // Utility methods
  const handleDelete = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        if (index === idx) {
          return { ...obj, status: PurchaseRequestStatus.DELETED };
        }
        return obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  const approve = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.APPROVED);
  };

  const preApprove = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.PRE_APPROVED);
  };

  const reject = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.REJECTED);
  };

  const changeRowStatus = (index, newStatus) => {
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        return index === idx
          ? {
              ...obj,
              status: newStatus
            }
          : obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  // ------------------- SUBMIT - SINGLE && MULTI ROW -------------------
  function _settingPurcheaseRequestSingleRow(values: any, requested_shipment_date: any) {
    setPurchaseRequest((prevState) => {
      const newLines = [];
      // if the request is periodic
      if (values.programmed) {
        // check if the order is standard
        if (values.quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            requested_shipment_date,
            is_periodic: true,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
        //Check if the order is discounted
        if (values.discounted_quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            discounted: true,
            requested_shipment_date,
            quantity: values.discounted_quantity,
            is_periodic: true,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
      } else {
        // check if the order is standard
        if (values.quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
        //Check if the order is discounted
        if (values.discounted_quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            discounted: true,
            quantity: values.discounted_quantity,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
      }

      return {
        ...prevState,
        lines: prevState.lines.concat(newLines)
      };
    });
  }

  const onSubmitRowPostQuantityPerPacketCheck = (values: any | null) => {
    // Check if the contract and warehouse are valid
    let errors = false;
    if (!contractOptions.map((c) => c.id).includes(values['contract_line'])) {
      toast.error(t(`${toastTranlationPrefix}.contract-error`));
      errors = true;
    }
    if (!warehouseOptions.map((c) => c.code).includes(values['warehouse'])) {
      toast.error(t(`${toastTranlationPrefix}.warehouse-error`));
      errors = true;
    }
    if (errors) return;
    // MULTIPLE ROWS
    if (values.programmed) {
      const startDate = dayjs(values.start_programmed);
      let currentDate = startDate,
        lastValidDay,
        lastValidDayOfWeek,
        lastValidWeek = 0,
        lastValidMonth;
      const endDate = dayjs(values.end_programmed);
      do {
        lastValidDay = currentDate.format('DD');
        lastValidDayOfWeek = currentDate.day();
        lastValidWeek += lastValidDayOfWeek === startDate.day() ? 1 : 0;
        lastValidMonth = currentDate.month();

        if (
          (values.rep_unit === 1 &&
            lastValidWeek % values.rep_frequency === 0 &&
            values.day_of_month == +lastValidDayOfWeek) ||
          (values.rep_unit === 2 &&
            (lastValidMonth - startDate.month()) % values.rep_frequency === 0 &&
            +values.day_of_month === +lastValidDay)
        ) {
          ((requested_shipment_date) => {
            _settingPurcheaseRequestSingleRow(values, requested_shipment_date);
          })(currentDate.format('YYYY-MM-DD'));
        }

        currentDate = currentDate.add(1, 'day');
      } while (currentDate.isBefore(endDate));
    } else {
      // SINGLE ROW
      _settingPurcheaseRequestSingleRow(values, null);
    }
  };

  const onSubmitRow = (values) => {
    if (parseInt(values?.quantity || 0) === 0 && parseInt(values?.discounted_quantity || 0) === 0) {
      toast.error(t(`${toastTranlationPrefix}.quantity-error`));
      return;
    }
    // Check if the quantity per units of package is valid
    if (
      unitsPerPackage &&
      (values.quantity || values.discounted_quantity) &&
      (parseInt(values?.quantity || 0) + parseInt(values?.discounted_quantity || 0)) %
        unitsPerPackage !==
        0
    ) {
      handleOpenConfirmationDialog(values);
    } else {
      onSubmitRowPostQuantityPerPacketCheck(values);
    }
  };

  // ------------------- Confirmation dialog -------------------
  const handleOpenConfirmationDialog = (values) => {
    setOpendConfirmationDialog(true);
    setConfirmationValues(values);
  };

  const handleCloseConfirmationDialog = () => {
    setOpendConfirmationDialog(false);
  };

  const handleSubmitConfirmation = () => {
    onSubmitRowPostQuantityPerPacketCheck(confirmationValues);
    setConfirmationValues(null);
    setOpendConfirmationDialog(false);
  };

  // utility function
  function mapLine(item) {
    return {
      ...item,
      id: +item.id || null,
      product: item.product['code']
    };
  }

  const getOrderPreview = () => {
    if (purchaseRequestId) {
      purchaseRequestService
        .getOrderPreview({ ...purchaseRequest, id: +purchaseRequestId })
        .then((res) => {
          setRequestPreview(res);
          setOpenPreviewDialog(true);
        });
    }
  };

  const getOrderPreviewInnerContent = () => {
    return (
      requestPreview &&
      Object.keys(requestPreview)
        .filter((k) => k !== 'detail')
        .map((v, i) => {
          // Supplier
          const supplier = requestPreview[v];
          const supplierDetail = requestPreview[v]['detail'] as ISupplier;
          return (
            <div key={v + i} className="px-3 mb-3">
              <b>{supplierDetail['company_name']}</b>
              <ul>
                {Object.keys(supplier)
                  .filter((k) => k !== 'detail')
                  .map((k) => {
                    const warehouse = requestPreview[v][k];
                    const warehouseDetail = requestPreview[v][k]['detail'] as IWarehouse;

                    const lines = warehouse['lines'] as IPurchaseRequestRow[];

                    return (
                      <div key={warehouseDetail.id} className="px-3">
                        {warehouseDetail.description}
                        <ul className="px-3">
                          {lines.map((line) => (
                            <li key={warehouse['id'] + '-' + line.id}>
                              <b>{line.product}</b> - {line.quantity}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
              </ul>
            </div>
          );
        })
    );
  };

  const changesToSave = (): boolean => {
    return JSON.stringify(initialRequest) === JSON.stringify(purchaseRequest);
  };

  // ------------------- RENDER COMPONENTS -------------------
  const columns: GridColumns = PurchaseLinesColumns(reject, approve, preApprove, handleDelete);

  const onEditCell = (params: GridCellEditCommitParams, event: MuiEvent) => {
    if (+params.id) {
      const payload = {};
      if (params.field == '_quantity') {
        payload['quantity'] = params.value;
      } else {
        payload[params.field] = params.value;
      }
      if (payload[params.field])
        purchaseRequestLineService.update(+params.id, { ...payload }).then(() => {
          toast.success(t('toasts.updated-cell'));
        });
    } else {
      setPurchaseRequest({
        ...purchaseRequest,
        lines: purchaseRequest.lines.map((line) => {
          if (line.id === params.id) {
            if (params.value instanceof Date) {
              line[params.field] = dayjs(params.value).format('YYYY-MM-DD');
            } else {
              if (params.field == '_quantity') {
                line['quantity'] = params.value;
              } else {
                line[params.field] = params.value;
              }
            }
          }
          return line;
        })
      });
    }
  };

  const CustomToolbar = () => <ExportButtonToolbar purchaseRequestId={purchaseRequestId} />;

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`, { id: purchaseRequest['id'] })}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex items-center justify-end">
            <div className="w-60">
              <CustomizedSteppers status={purchaseRequest.status}></CustomizedSteppers>
            </div>
            {purchaseRequest.status !== 'ORDER_SENT' && (
              <ButtonGroup size="small" variant="outlined">
                <Button disabled={changesToSave()} onClick={saveRequest}>
                  <Tooltip title={t('global.save')}>
                    <SaveIcon />
                  </Tooltip>
                </Button>
                <IsTenantCoordinator>
                  <Button disabled={!changesToSave()} onClick={reviewRequest}>
                    {t(`pages.purchase-requests.new.pre-approve`)}
                  </Button>
                </IsTenantCoordinator>
                {purchaseRequestId && (
                  <IsTenantAdministrator>
                    <Button disabled={!changesToSave()} color="success" onClick={getOrderPreview}>
                      {t('pages.purchase-requests.new.generate-order')}
                    </Button>
                  </IsTenantAdministrator>
                )}
              </ButtonGroup>
            )}
          </div>
        }>
        <Accordion className="bg-slate-50 my-8">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" color="primary">
              <b>{t('pages.purchase-requests.new.add-element')}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <PurchaseRequestLineForm
                  onSubmit={onSubmitRow}
                  products={productOptions}
                  setProduct={setProductToAdd}
                  contracts={contractOptions}
                  setContract={setContractToAdd}
                  warehouses={warehouseOptions}
                  setWarehouse={setWarehouseToAdd}
                  maxUnits={maxUnits}
                  maxDiscountedUnits={maxDiscountedUnits}
                />
              </div>
              <div className="m-2">
                <InfoBoxPurchaseRequestCard
                  product_code={visibleProduct?.code || null}
                  product_description={visibleProduct?.description || undefined}
                  stockQuantities={visibleProduct?.stockQuantities || null}
                  productWarehouse={visibleProduct?.productWarehouse || null}
                  orderLines={visibleProduct?.orderLines || null}
                  consumes={visibleProduct?.consume || null}
                  unitPrice={visibleProduct?.unitPrice || null}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Item list */}
        <div>
          <DataGridPremium
            density="compact"
            initialState={{
              pinnedColumns: {
                left: ['code', 'approved'],
                right: ['actions']
              }
            }}
            rows={purchaseRequest.lines
              .filter((row) => row.status !== PurchaseRequestStatus.DELETED)
              .map((l) => {
                return { id: uuidv4(), ...l, description: l.product['description'] };
              })}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Toolbar: CustomToolbar
            }}
            sx={{ border: 'none' }}
            autoHeight
            disableSelectionOnClick
            pagination
            columns={columns}
            onCellEditCommit={onEditCell}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
          />
        </div>

        {/* Send order */}
        <div>
          <Dialog
            open={openPreviewDialog}
            onClose={handleClosePreviewDialog}
            fullWidth
            maxWidth="md">
            <DialogTitle id="alert-dialog-title">{'Anteprima ordine'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>{t('pages.purchase-requests.new.order-preview-description')}</p>
                {getOrderPreviewInnerContent()}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="w-full flex justify-between">
                <Button onClick={handleClosePreviewDialog}>{t('actions.cancel')}</Button>
                <div>
                  <Button className="me-2" onClick={() => fulfillRequest(false)}>
                    {t('actions.confirm')}
                  </Button>
                  <Button variant="contained" onClick={() => fulfillRequest(true)} autoFocus>
                    {t('actions.confirm-send-email')}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        {/* Confirmation dialog */}
        <div>
          <Dialog
            open={opendConfirmationDialog}
            onClose={handleCloseConfirmationDialog}
            fullWidth
            maxWidth="md">
            <DialogTitle>
              {t('pages.purchase-requests.new.quantity-multiple-confirmation-title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('pages.purchase-requests.new.quantity-multiple-confirmation-text')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmationDialog} color="primary">
                {t('actions.cancel')}
              </Button>
              <Button onClick={handleSubmitConfirmation} color="primary">
                {t('actions.confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </TenantPage>
    </>
  );
};

export default PurchaseRequest;
