import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { IPurchaseRequest } from 'interfaces';
import CustomizedSteppers from 'components/common/RequestStatus';
import SectionTitle from 'components/common/SectionTitle';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dateCell } from '../cells/date-cell';
import { dateFilterOperators } from '../../../../constants';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import { baseCell } from '../cells/base-cell';

export function PurchaseAutomaticLineColumns(message: string, handleDelete?: any): GridColDef[] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translationPrefix = 'pages.purchase-requests.list';

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: t('entities.purchase-request.ID'),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const pr = params.row as IPurchaseRequest;
        return (
          <a
            onClick={() => {
              const currentPath = window.location.pathname;
              const parts = currentPath.split('/');
              parts.pop();
              const newPath = `${parts.join('/')}/view/${params.row.id}`;
              navigate(newPath);
            }}>
            <SectionTitle>
              {t(`${translationPrefix}.request-description`, { id: pr.id })}
            </SectionTitle>
          </a>
        );
      }
    },
    {
      field: 'created_by',
      width: 100,
      headerName: t('entities.purchase-request.proposer')
    },
    {
      field: 'warehouse',
      width: 250,
      headerName: t('entities.purchase-request.warehouse'),
      renderCell: baseCell
    },
    {
      field: 'created_at',
      width: 200,
      headerName: t('entities.purchase-request.created_at'),
      filterOperators: dateFilterOperators,
      renderCell: dateCell
    },
    {
      field: 'status',
      width: 200,
      headerName: t('entities.purchase-request.status'),
      renderCell: (props: GridRenderCellParams) => {
        const pr = props.row as IPurchaseRequest;
        return (
          <div className="w-full flex flex-row justify-end items-center">
            <div className="w-60">
              <CustomizedSteppers status={pr.status}></CustomizedSteppers>
            </div>
          </div>
        );
      }
    },
    {
      field: '',
      flex: 1,
      headerName: t('global.actions'),
      align: 'right',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <IsTenantAdministrator key={2}>
            <ConfirmationDeleteTableDialog
              handleDelete={handleDelete}
              message={message}
              id={+params.id}
            />
          </IsTenantAdministrator>
        ];
      }
    }
  ];
  return columns;
}
