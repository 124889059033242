import {
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
  getGridBooleanOperators,
  getGridSingleSelectOperators
} from '@mui/x-data-grid-premium';
import { IProductState, IPurchaseRequest } from '../interfaces';

export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ROLE_TENANT_ADMIN = 'TENANT_ADMIN';
export const ROLE_TENANT_COORDINATOR = 'COORDINATOR';
export const ROLE_TENANT_TECHNICIAN = 'TECHNICIAN';
export const ROLE_TENANT_OPERATOR = 'OPERATOR';

export const SuperAdminRoles = [ROLE_SUPER_ADMIN];
export const NotOperatorRoles = [
  ROLE_SUPER_ADMIN,
  ROLE_TENANT_ADMIN,
  ROLE_TENANT_COORDINATOR,
  ROLE_TENANT_TECHNICIAN
];
export const TenantRoles = [
  ROLE_TENANT_ADMIN,
  ROLE_TENANT_COORDINATOR,
  ROLE_TENANT_TECHNICIAN,
  ROLE_TENANT_OPERATOR
];

export const PurchaseRequestStatus = {
  NEW: 1,
  PRE_APPROVED: 2,
  APPROVED: 3,
  REJECTED: 4,
  DELETED: 5
};

export const TransportDocumentActiveStatus = {
  NEW: 1,
  COMPLETED: 1,
  SENT: 2,
  ARRIVED: 3
};

export const TransportDocumentActiveLineStatus = {
  NEW: 1,
  SENT: 2
};

export const EquipmentInvoiceLineType = {
  PURCHASE: 'PURCHASE',
  LEASE: 'LEASE',
  ASSISTANCE: 'ASSISTANCE',
  ADDITIONAL_COST: 'ADDITIONAL_COST'
};

export const productInitialState: IProductState = {
  id: null,
  code: '',
  description: '',
  type: null,
  category: null,
  status: '',
  expiration_alert_threshold: 0,
  preservation_temperature: 0.0,
  locations: []
};

export const purchReqInitialState: IPurchaseRequest = {
  id: null,
  status: '',
  created_by: null,
  lines: []
};

export const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals'].includes(value)
);

export const numberFilterOperators = getGridNumericOperators().filter(({ value }) =>
  ['=', '>', '>=', '<', '<='].includes(value)
);

export const dateFilterOperators = getGridDateOperators().filter(({ value }) =>
  ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'].includes(value)
);

export const booleanFilterOperators = getGridBooleanOperators().filter(({ value }) =>
  ['is'].includes(value)
);

export const singleSelectFilterOperators = getGridSingleSelectOperators().filter(({ value }) =>
  ['is'].includes(value)
);

export const italianDistricts = [
  { id: 1, code: 'AG' },
  { id: 2, code: 'AL' },
  { id: 3, code: 'AN' },
  { id: 4, code: 'AR' },
  { id: 5, code: 'AP' },
  { id: 6, code: 'AT' },
  { id: 7, code: 'AV' },
  { id: 8, code: 'BA' },
  { id: 9, code: 'BT' },
  { id: 10, code: 'BL' },
  { id: 11, code: 'BN' },
  { id: 12, code: 'BG' },
  { id: 13, code: 'BI' },
  { id: 14, code: 'BO' },
  { id: 15, code: 'BZ' },
  { id: 16, code: 'BS' },
  { id: 17, code: 'BR' },
  { id: 18, code: 'CA' },
  { id: 19, code: 'CL' },
  { id: 20, code: 'CB' },
  { id: 21, code: 'CI' },
  { id: 22, code: 'CE' },
  { id: 23, code: 'CT' },
  { id: 24, code: 'CZ' },
  { id: 25, code: 'CH' },
  { id: 26, code: 'CO' },
  { id: 27, code: 'CS' },
  { id: 28, code: 'CR' },
  { id: 29, code: 'KR' },
  { id: 30, code: 'CN' },
  { id: 31, code: 'EN' },
  { id: 32, code: 'FM' },
  { id: 33, code: 'FE' },
  { id: 34, code: 'FI' },
  { id: 35, code: 'FG' },
  { id: 36, code: 'FC' },
  { id: 37, code: 'FR' },
  { id: 38, code: 'GE' },
  { id: 39, code: 'GO' },
  { id: 40, code: 'GR' },
  { id: 41, code: 'IM' },
  { id: 42, code: 'IS' },
  { id: 43, code: 'AQ' },
  { id: 44, code: 'SP' },
  { id: 45, code: 'LT' },
  { id: 46, code: 'LE' },
  { id: 47, code: 'LC' },
  { id: 48, code: 'LI' },
  { id: 49, code: 'LO' },
  { id: 50, code: 'LU' },
  { id: 51, code: 'MC' },
  { id: 52, code: 'MN' },
  { id: 53, code: 'MS' },
  { id: 54, code: 'MT' },
  { id: 55, code: 'VS' },
  { id: 56, code: 'ME' },
  { id: 57, code: 'MI' },
  { id: 58, code: 'MO' },
  { id: 59, code: 'MB' },
  { id: 60, code: 'NA' },
  { id: 61, code: 'NO' },
  { id: 62, code: 'NU' },
  { id: 63, code: 'OG' },
  { id: 64, code: 'OT' },
  { id: 65, code: 'OR' },
  { id: 66, code: 'PD' },
  { id: 67, code: 'PA' },
  { id: 68, code: 'PR' },
  { id: 69, code: 'PV' },
  { id: 70, code: 'PG' },
  { id: 71, code: 'PU' },
  { id: 72, code: 'PE' },
  { id: 73, code: 'PC' },
  { id: 74, code: 'PI' },
  { id: 75, code: 'PT' },
  { id: 76, code: 'PN' },
  { id: 77, code: 'PZ' },
  { id: 78, code: 'PO' },
  { id: 79, code: 'RG' },
  { id: 80, code: 'RA' },
  { id: 81, code: 'RC' },
  { id: 82, code: 'RE' },
  { id: 83, code: 'RI' },
  { id: 84, code: 'RN' },
  { id: 85, code: 'RM' },
  { id: 86, code: 'RO' },
  { id: 87, code: 'SA' },
  { id: 88, code: 'SS' },
  { id: 89, code: 'SV' },
  { id: 90, code: 'SI' },
  { id: 91, code: 'SR' },
  { id: 92, code: 'SO' },
  { id: 93, code: 'TA' },
  { id: 94, code: 'TE' },
  { id: 95, code: 'TR' },
  { id: 96, code: 'TO' },
  { id: 97, code: 'TP' },
  { id: 98, code: 'TN' },
  { id: 99, code: 'TV' },
  { id: 100, code: 'TS' },
  { id: 101, code: 'UD' },
  { id: 102, code: 'AO' },
  { id: 103, code: 'VA' },
  { id: 104, code: 'VE' },
  { id: 105, code: 'VB' },
  { id: 106, code: 'VC' },
  { id: 107, code: 'VR' },
  { id: 108, code: 'VV' },
  { id: 109, code: 'VI' },
  { id: 110, code: 'VT' }
];
