import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import SectionTitle from 'components/common/SectionTitle';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { dateFilterOperators, filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';

import { dateCell } from '../cells/date-cell';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import DataGridCell from 'components/common/DataGridCell';

export function TransportActiveDocumentColumns(
  isLinkable: boolean,
  downloadAttachment: any,
  regenerateDocument: any
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: t(`entities.ddt.ref`),
      width: 175,
      renderCell: (params: GridRenderCellParams<string>) =>
        isLinkable ? (
          <NavLink to={`./${params.row?.id}`}>
            <SectionTitle>{params.value}</SectionTitle>
          </NavLink>
        ) : (
          <SectionTitle>{params.value}</SectionTitle>
        )
    },
    {
      field: 'date',
      headerName: t(`entities.ddt.date`),
      filterOperators: dateFilterOperators,
      width: 125,
      renderCell: dateCell
    },
    {
      field: 'order',
      headerName: t(`global.order`),
      filterOperators,
      width: 125,
      renderCell: baseCell
    },
    {
      field: 'warehouse_out',
      headerName: t(`global.warehouse_out`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'warehouse_in',
      headerName: t(`global.warehouse_in`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      filterOperators,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={t(`entities.active-ddt.status.${params.value}`)} />
      )
    }
  ];

  columns.push({
    field: 'actions',
    type: 'actions',
    headerName: t(`global.actions`),
    getActions: (params: GridRowParams) => {
      const actions = [];
      actions.push(
        <GridActionsCellItem
          key={998}
          icon={<CloudDownloadIcon />}
          onClick={() => downloadAttachment(params.row?.id)}
          label="Download"
        />
      );
      actions.push(
        <GridActionsCellItem
          icon={<ReplayIcon />}
          onClick={() => regenerateDocument(params.row?.id)}
          label="Regenerate"
        />
      );

      return actions;
    }
  });

  return columns;
}
