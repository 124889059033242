import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import toast from 'features/toast';
import { Dispatch, SetStateAction, useState } from 'react';
import StyledDropzone from 'components/common/StyledDropzone';
import PublishIcon from '@mui/icons-material/Publish';
import { useTranslation } from 'react-i18next';
import { medicalServiceService } from 'services/api';

interface LISFileUploaderProps {
  onUploadSuccess: any;
  openDropzone: boolean;
  setOpenDropzone: Dispatch<SetStateAction<boolean>>;
  showButton?: boolean;
}

export const LISFileUploader = (props: LISFileUploaderProps) => {
  const { t } = useTranslation();
  const { onUploadSuccess, openDropzone, setOpenDropzone, showButton } = props;

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const attachFile = () => {
    setOpenDropzone(true);
  };

  const handleUpload = () => {
    if (fileToUpload) {
      medicalServiceService.importLisFile(fileToUpload).then(() => {
        if (onUploadSuccess) onUploadSuccess();
        toast.success(t('global.import-started'));
        setOpenDropzone(false);
      });
    }
  };

  const handleClose = () => {
    setFileToUpload(null);
    setOpenDropzone(false);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      toast.error(t('global.import-size-error'));
      return;
    }

    const file = acceptedFiles[0];
    if (file) {
      setFileToUpload(file);
    }
  };

  const importButtonProps: ButtonProps = {
    color: 'success',
    size: 'small',
    startIcon: <PublishIcon />
  };

  return (
    <>
      {showButton && (
        <Button {...importButtonProps} onClick={attachFile}>
          {t('data-grid.import')}
        </Button>
      )}
      <Dialog open={openDropzone}>
        <DialogTitle>{t(`import-modal.title`)}</DialogTitle>
        <DialogContent>
          <StyledDropzone onDrop={handleDrop} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('actions.cancel')}</Button>
          <Button onClick={handleUpload} disabled={!fileToUpload}>
            {t('actions.upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LISFileUploader;
