import { GridColDef, GridColumns, GridRowParams } from '@mui/x-data-grid-premium';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';

export function MedicalServicesExtraTranscodeColumns(handleDelete?: any): GridColDef[] {
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: 'field',
      headerName: t(`global.field`),
      filterOperators,
      width: 200,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'transcode',
      headerName: t(`global.transcode`),
      filterOperators,
      editable: true,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <ConfirmationDeleteTableDialog key={997} id={params.row.id} handleDelete={handleDelete} />
        ];
        return actions;
      },
      filterable: false
    }
  ];
  return columns;
}
