import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IMedicalServiceExtraTranscode } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface MedicalServiceExtraTranscodeFormProps {
  transcode: IMedicalServiceExtraTranscode;
  onSubmit: any;
}
function MedicalServiceExtraTranscodeForm(props: MedicalServiceExtraTranscodeFormProps) {
  const { t } = useTranslation();
  const { transcode, onSubmit } = props;
  const extra_fields = [
    { code: 'extra_1', description: 'Extra 1' },
    { code: 'extra_2', description: 'Extra 2' },
    { code: 'extra_3', description: 'Extra 3' },
    { code: 'extra_4', description: 'Extra 4' },
    { code: 'extra_5', description: 'Extra 5' },
    { code: 'extra_6', description: 'Extra 6' },
    { code: 'extra_7', description: 'Extra 7' }
  ];

  return (
    <div>
      {transcode && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!transcode.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-0 md:pr-4">
              <IdentityField
                name="field"
                component={AutocompleteAdapter}
                label={t('global.field')}
                options={extra_fields}
                optionValue="code"
                optionLabel="description"
                type="text"
                required
              />
            </div>
            <div className="w-full md:w-1/2 ">
              <IdentityField
                name="transcode"
                component={TextFieldAdapter}
                label={t('global.transcode')}
                placeholder={t('global.transcode')}
                type="text"
                required
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default MedicalServiceExtraTranscodeForm;
