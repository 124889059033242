import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import DataGridCell from 'components/common/DataGridCell';
import { filterOperators } from '../../../../constants';
import { Dayjs } from 'dayjs';
import { NavLink } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import productStatusCell from '../cells/product-status-cell';
import { currencyCell } from '../cells/currency-cell';

export function ConsumptionsColumns(startDate: Dayjs, endDate: Dayjs): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';
  const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const columns = [
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NavLink to={`../products/${params.row.product_id}`}>
          <b className="text-primary-600">{params.value}</b>
        </NavLink>
      )
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      width: 150,
      renderCell: productStatusCell(t),
      filterable: false,
      sortable: false
    },
    {
      field: 'product_type',
      valueGetter: (params: GridValueFormatterParams) => params.value,
      renderCell: baseCell,
      headerName: t(`global.product_type`),
      type: 'string',
      width: 100
    },
    {
      field: 'unit_price',
      type: 'string',
      headerName: t(`${translationPrefix}.unit_price`),
      filterable: false,
      sortable: false,
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'amount',
      type: 'string',
      headerName: t(`${translationPrefix}.amount`),
      filterable: false,
      sortable: false,
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'avg',
      type: 'number',
      headerName: t(`${translationPrefix}.avg_quantity`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {params.value} </b>
      )
    },
    {
      field: 'tot',
      type: 'number',
      headerName: t(`${translationPrefix}.total`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {params.value} </b>
      )
    },
    {
      field: 'label',
      type: 'string',
      renderCell: baseCell,
      headerName: t(`global.label`),
      width: 250
    },
    {
      field: 'description',
      type: 'string',
      headerName: t(`global.description`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      type: 'string',
      headerName: t(`global.supplier`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'notes',
      type: 'string',
      headerName: t(`global.notes`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'category',
      type: 'string',
      headerName: t(`global.category`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    }
  ];

  if (endDate && startDate) {
    for (
      let currentDate = startDate;
      endDate.isAfter(currentDate);
      currentDate = currentDate.add(1, 'month')
    ) {
      const year = currentDate.year();
      const month = currentDate.month();

      columns.push({
        field: `m_${month}__y_${year}`,
        type: 'number',
        headerName: `${t(`months.${monthNames[month]}`)} ${year}`,
        filterable: false,
        sortable: false,
        width: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
          <DataGridCell content={params.value} />
        )
      });
    }
  }

  return columns;
}
