import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { IOption, ITransportActiveDocument } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { locationService, productService, stockQuantityService } from 'services/api';
import toast from 'features/toast';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import { transportActiveDocumentService } from 'services/api/TransportActiveDocumentService';
import { transportDocumentActiveLineService } from 'services/api/TransportActiveDocumentLineService';
import TransportDocumentActiveForm from '../TransportActiveDocumentForm';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TransportActiveDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-active-document-lines';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransportActiveDocumentLineForm from '../TransportActiveDocumentLineForm';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import CustomizedSteppers from 'components/common/TransportActiveDocumentStatus';

export function TransportActiveDocumentDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.active-ddt.detail';
  const { id } = useParams();

  const [ddt, setDdt] = useState<ITransportActiveDocument>();
  const [initialDdtLines, setInitialDdtLines] = useState<any>();

  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<IOption[]>([]);
  const [batchesOption, setBatchesOptions] = useState<IOption[]>([]);

  const [productToAdd, setProductToAdd] = useState<any>(null);

  const handleDelete = (id: number) => {
    transportDocumentActiveLineService.delete(id).then((res) => {
      if (res && res.status === 204) {
        toast.success(t(`${prefix}.line-deleted-success`));
      } else {
        toast.error(t(`${prefix}.line-deleted-error`));
      }
    });
    getDDTById();
  };

  const getDDTById = () => {
    transportActiveDocumentService.get(+id).then((res) => {
      setDdt(res);
      setInitialDdtLines(res.lines);
    });
  };

  useEffect(() => {
    locationService.getAllBaseInformation().then(setLocationOptions);
    getDDTById();
  }, []);

  useEffect(() => {
    if (ddt && ddt.id) {
      productService
        .getAllBaseInformation({ warehouse_id: ddt.warehouse_out, status: 'ACTIVE' })
        .then(setProductOptions);
      // stockQuantityService
      //   .getAllPaginated(1000, 0, { warehouse__id: ddt.warehouse_out })
      //   .then((res) => {
      //     setProductOptions(res.results);
      //   });
      if (productToAdd?.id) {
        const params = {
          product__id: productToAdd?.id ?? '',
          warehouse__id: ddt.warehouse_out ?? '',
          quantity__gt: 0
        };
        stockQuantityService.getUnloadStocks(1000, 0, params).then((res) => {
          setBatchesOptions(
            res.results.map((value) => {
              let description = value.product_batch;
              if (value.expiration_date) description += `; Scad: ${value.expiration_date}`;
              description += `; Qty: ${value.total_quantity}`;
              return {
                id: value.product_batch,
                code: description,
                expiration_date: value.expiration_date,
                qty: value.total_quantity
              };
            })
          );
        });
      } else {
        setBatchesOptions([]);
      }
    }
  }, [ddt?.id, productToAdd?.id]);

  const manualRefresh = () => {
    getDDTById();
  };

  const columns: GridColDef[] = TransportActiveDocumentLinesColumns(handleDelete);

  const changesToSave = (): boolean => {
    return JSON.stringify(initialDdtLines) === JSON.stringify(ddt.lines);
  };

  const saveDDTLines = () => {
    transportDocumentActiveLineService.bulkCreate(ddt.lines).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.detail);
      } else {
        toast.error("Errore durante l'aggiunta dei prodotti al Trasferimento");
      }
    });
    setInitialDdtLines(ddt.lines);
  };

  const finalizeDdt = () => {
    transportActiveDocumentService.finalize(ddt.id).then((res) => {
      if ('warnings' in res) {
        res.warnings.map((warning) => {
          if (warning?.batches) {
            toast.warning(t('alerts.earlier-expiration', { batches: warning.batches.join(', ') }));
            return;
          }
          toast.warning(warning.type);
        });
      } else if ('error' in res) {
        toast.error(res.error);
      } else {
        toast.success('Trasferimento finalizzato con successo');
      }
      getDDTById();
    });
  };

  const generateTmpDdt = () => {
    transportActiveDocumentService.generateTmpPdf(ddt.id).then((res) => {
      toast.success('PDF provvisorio generato con successo');
    });
  };

  const downloadTmpDdt = () => {
    transportActiveDocumentService.downloadTmpPdf(ddt.id);
  };

  const updateDDT = (values: any) => {
    transportActiveDocumentService
      .update(ddt.id, {
        ...values,
        warehouse_out: values.warehouse_out['id'],
        warehouse_in: values.warehouse_in['id']
      })
      .then(() => {
        getDDTById();
        toast.success('Trasferimento aggiornato con successo');
      });
  };

  const onSubmitRow = (values: any) => {
    const product_batch_id = values['product_batch'].id;
    const expiration_date = values['product_batch'].expiration_date;
    const notes = values['location']?.code ?? '';
    values['product_code'] = values['product'].code;
    values['product_description'] = values['product'].description.replace(
      //correzione descrizion IOption
      values['product'].code + ' -',
      ''
    );
    values['expiration_date'] = expiration_date;
    values['location'] = values['location']?.id ?? null;
    values['product_batch'] = product_batch_id;
    values['document'] = ddt.id;
    values['notes'] = notes;
    values['id'] = uuidv4();
    const updatedLines = [...ddt.lines, values];
    setDdt({ ...ddt, lines: updatedLines });
  };

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + ddt?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      menuRight={
        <div className="flex items-center justify-end">
          <div className="w-60">
            <CustomizedSteppers status={ddt?.status}></CustomizedSteppers>
          </div>
          {ddt && (
            <ButtonGroup size="small" variant="outlined">
              <Button disabled={!changesToSave()} color="primary" onClick={generateTmpDdt}>
                {t(`${prefix}.generate-tmp`)}
              </Button>
              <Button disabled={!changesToSave()} color="primary" onClick={downloadTmpDdt}>
                {t(`${prefix}.download-tmp`)}
              </Button>
            </ButtonGroup>
          )}
          {ddt && ddt.status === 'NEW' && (
            <ButtonGroup size="small" variant="outlined">
              <Button disabled={changesToSave()} onClick={saveDDTLines}>
                <Tooltip title={t('global.save')}>
                  <SaveIcon />
                </Tooltip>
              </Button>
              <Button disabled={!changesToSave()} color="success" onClick={finalizeDdt}>
                {t(`${prefix}.finalize`)}
              </Button>
            </ButtonGroup>
          )}
        </div>
      }
      entityCode={ddt?.ref}>
      <div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="w-full my-4">
            {ddt && (
              <TransportDocumentActiveForm
                onSubmit={updateDDT}
                transport_document={ddt}
                isUpdate={true}
              />
            )}
          </div>
        </div>

        <div>
          <Accordion className="bg-slate-50 my-8">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t(`${prefix}.add-element`)}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <TransportActiveDocumentLineForm
                onSubmit={onSubmitRow}
                products={productOptions}
                setProduct={setProductToAdd}
                locations={locationOptions}
                batches={batchesOption}
              />
            </AccordionDetails>
          </Accordion>
          <div className="flex justify-between items-center">
            <div className="ml-auto">
              {manualRefresh && (
                <Tooltip title={t('global.refresh-table')}>
                  <IconButton onClick={manualRefresh} color="primary" size="large">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="w-full p-4">
            <DataGridPremium
              density="compact"
              initialState={{
                pinnedColumns: {
                  left: ['code', 'approved'],
                  right: ['actions']
                }
              }}
              rows={ddt?.lines ?? []}
              // components={{
              //   Toolbar: CustomToolbar
              // }}
              sx={{ border: 'none' }}
              autoHeight
              disableSelectionOnClick
              pagination
              columns={columns}
              //onCellEditCommit={onEditCell}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
          </div>
        </div>
      </div>
    </TenantPage>
  );
}

export default TransportActiveDocumentDetail;
