import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { medicalServiceExtraFieldTrancodesService } from 'services/api/MedicalServiceExtraFieldTrancodes';
import MedicalServiceExtraTranscodeForm from '../MedicalServiceExtraTranscodeForm';

const initTranscode = {
  id: null,
  field: null,
  transcode: null
};

export const CreateMedicalServiceExtraTrancode = () => {
  const translationPrefix = 'pages.extra-field-transcode.new';

  const { t } = useTranslation();

  const createTranscode = async (values) => {
    medicalServiceExtraFieldTrancodesService.add(values).then((res) => {
      if (res) toast.success('Transcodifica aggiunta con successo.');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <MedicalServiceExtraTranscodeForm transcode={initTranscode} onSubmit={createTranscode} />
    </TenantPage>
  );
};

export default CreateMedicalServiceExtraTrancode;
