import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { currencyCell } from '../cells/currency-cell';
import TransportDocumentLineUpdateDialog from 'components/invoices/TransportDocument/detail/TransportDocumentLineUpdateDialog';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Tooltip } from '@mui/material';

export function TransportDocumentLinesColumns(
  locked = false,
  headerInfo = true,
  onCloseUpdate?: () => void,
  handleCheck?: (id: any) => void,
  handleUncheck?: (id: any) => void,
  handleUpdateExcluded?: (id: any, excluded: boolean) => void
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'checkbox',
      headerName: t('global.print'),
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        const { id } = params.row; // Supponendo che l'ID sia presente in ogni riga

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.checked) {
            handleCheck?.(id); // Chiama la funzione `handleCheck` con l'ID
          } else {
            handleUncheck?.(id); // Chiama la funzione `handleUncheck` con l'ID
          }
        };

        return (
          <input
            type="checkbox"
            disabled={locked} // Disabilita la checkbox se `locked` è true
            onChange={handleChange}
          />
        );
      }
    },
    {
      field: 'quantity',
      headerName: t(`global.quantity`),
      width: 80,
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    }
  ];

  if (!handleUpdateExcluded) {
    columns.push(
      // 17/12/2024 : Divisione fra DDT e Fattura
      // Richiesta da Stefano l'eliminazione della colonna "importo fatturato"
      {
        field: 'amount',
        headerName: t(`entities.ddt.amount`),
        filterOperators: numberFilterOperators,
        type: 'number',
        width: 125,
        sortable: false,
        editable: !locked,
        valueFormatter: (params: GridValueFormatterParams) => {
          return currencyFormatter(params.value);
        },
        renderCell: currencyCell
      }
    );
  }

  columns.push(
    {
      field: 'expected_amount',
      headerName: t(`entities.ddt.expected_amount`),
      filterOperators: numberFilterOperators,
      sortable: false,
      type: 'number',
      width: 125,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    {
      field: 'document',
      headerName: t(`entities.ddt.ref`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    },
    {
      // richiesta di Stefano per ROMA 2 : 18/12/2024
      field: 'notes',
      headerName: t(`entities.ddt.notes`),
      filterOperators,
      sortable: false,
      editable: true,
      width: 200,
      renderCell: baseCell
    }
  );

  // if (headerInfo) {
  // 17/12/2024 : Divisione fra DDT e Fattura
  // Richiesta da Stefano l'eliminazione della colonna "importo fatturato"
  // columns.push(
  //   ...[
  // {
  //   field: 'invoice_ref',
  //   headerName: t(`entities.ddt.invoice_ref`),
  //   filterOperators,
  //   sortable: false,
  //   width: 100,
  //   renderCell: baseCell
  // },
  // {
  //   field: 'credit_memo_ref',
  //   headerName: t(`entities.ddt.credit_memo_ref`),
  //   filterable: false,
  //   sortable: false,
  //   width: 100,
  //   renderCell: baseCell
  // },
  // {
  //   field: 'credit_memo_date',
  //   headerName: t(`entities.ddt.credit_memo_date`),
  //   filterable: false,
  //   sortable: false,
  //   width: 100,
  //   renderCell: baseCell
  // },
  // {
  //   field: 'credit_memo_amount',
  //   headerName: t(`entities.ddt.credit_memo_amount`),
  //   filterable: false,
  //   sortable: false,
  //   width: 100,
  //   valueFormatter: (params: GridValueFormatterParams) => {
  //     return currencyFormatter(params.value);
  //   },
  //   renderCell: currencyCell
  // }
  //     ]
  //   );
  // }

  columns.push(
    ...[
      {
        field: 'product',
        headerName: t(`global.product`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'description',
        headerName: t(`global.description`),
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: baseCell
      },
      {
        field: 'contract',
        headerName: t(`global.contract`),
        filterable: false,
        width: 75,
        renderCell: baseCell
      },
      {
        field: 'supplier',
        headerName: t(`global.supplier`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'warehouse',
        headerName: t(`global.warehouse`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'date',
        headerName: t(`entities.ddt.movement_date`),
        filterOperators,
        width: 110,
        renderCell: baseCell
      },
      {
        field: 'discounted',
        width: 50,
        headerName: t(`entities.movement.discounted`),
        renderCell: booleanCell(t)
      },
      {
        field: 'unit_price',
        headerName: t(`entities.movement.unit_price`),
        filterOperators: numberFilterOperators,
        sortable: false,
        type: 'number',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
          return currencyFormatter(params.value);
        },
        renderCell: currencyCell
      },
      {
        field: 'order_code',
        headerName: t(`entities.movement.order_code`),
        filterOperators,
        sortable: false,
        width: 150,
        renderCell: baseCell
      }
    ]
  );

  if (onCloseUpdate) {
    columns.push({
      field: 'actions',
      type: 'actions',
      width: 75,
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        return [
          <TransportDocumentLineUpdateDialog
            key={980}
            ddtLineId={params.row.id}
            supplierId={params.row.supplier_id}
            warehouseId={params.row.warehouse_id}
            onClose={onCloseUpdate}
          />
        ];
      },
      filterable: false,
      sortable: false
    });
  }

  return columns;
}
