import { ITransportActiveDocumentLine } from 'interfaces';
import { BaseService } from './BaseService';
import { axiosPrivate } from 'services/axios';

class TransportDocumentActiveLineService extends BaseService<ITransportActiveDocumentLine> {
  constructor() {
    super('transport-active-document-lines');
  }

  bulkCreate = async (lines: ITransportActiveDocumentLine[]) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/bulk-create/`, {
      lines
    });
    return res;
  };
}

export const transportDocumentActiveLineService = new TransportDocumentActiveLineService();
