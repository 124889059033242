import { purchaseRequestService } from 'services/api';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { useRef } from 'react';
import toast from 'features/toast';
import { GridColDef } from '@mui/x-data-grid-premium';
import { PurchaseAutomaticLineColumns } from 'components/common/enhanced/common-headers/purchase-request-automatic';

export const PurchaseRequestAutomaticList = () => {
  const translationPrefix = 'pages.purchase-requests.automatic-list';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const handleDelete = (id: number) => {
    purchaseRequestService.delete(id).then(() => {
      toast.success(t('actions.successfully.deleted'));
      datagridRefresh.current();
    });
  };

  const columns: GridColDef[] = PurchaseAutomaticLineColumns(
    t(`${translationPrefix}.delete-confirmation-extended`),
    handleDelete
  );

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <EnhancedDataGrid
          columns={columns}
          service={purchaseRequestService}
          refresh={datagridRefresh}
          outerParams={{
            automatic_generated: true
          }}
          disableDelete
        />
      </TenantPage>
    </>
  );
};

export default PurchaseRequestAutomaticList;
