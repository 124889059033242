import { IMedicalService } from 'interfaces';
import { BaseService } from './BaseService';

class MedicalServiceExtraFieldTrancodesService extends BaseService<IMedicalService> {
  constructor() {
    super('medical-service-extra-field-transcodes');
  }
  canBulkDelete = true;
}

export const medicalServiceExtraFieldTrancodesService =
  new MedicalServiceExtraFieldTrancodesService();
