import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { UnapprovedMovementColumns } from 'components/common/enhanced/common-headers/unapproved-movements';
import { AggregatedUnapprovedMovementColumns } from 'components/common/enhanced/common-headers/unapproved-movements-aggregated';
import {
  movementReasonService,
  movementService,
  unapprovedMovementService,
  warehouseService
} from 'services/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IOption, IProductWarehouseContract } from 'interfaces';
import { GridSelectionModel } from '@mui/x-data-grid';
import toast from 'features/toast';
import DetailPanelContent from './DetailPanelContent';
import { useParams, useSearchParams } from 'react-router-dom';
import EditUnapprovedMovement from '../EditUnapprovedMovement';
import dayjs from 'dayjs';

export const UnapprovedMovements = () => {
  const translationPrefix = 'pages.movements.unapproved';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const [searchParams] = useSearchParams();

  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const selectedMovements = useRef<Record<string, GridSelectionModel>>({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [movementToDelete, setMovementToDelete] = useState<number | null>(null);
  const [pwc, setPwc] = useState<IProductWarehouseContract>(null);
  const [identificators, setIdentificators] = useState<number[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { transportDocument } = useParams();
  const noDDT = searchParams.get('noDDT');

  useEffect(() => {
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  const setOpenDialog = (row: any) => {
    setIdentificators(row.ids);
    setPwc({
      product: row.product,
      warehouse: row.warehouse,
      contract: row.contract,
      date: row.timestamp && dayjs(row.timestamp).format('DD/MM/YYYY')
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const onSubmitEdit = async (values) => {
    unapprovedMovementService.editAggregatedRow(identificators, values.contract).then((res) => {
      setOpenEditDialog(false);
      if (datagridRefresh?.current) datagridRefresh.current();
      toast.success(t(`${translationPrefix}.edit-success`));
    });
  };

  const aggregatedColumns: GridColDef[] = AggregatedUnapprovedMovementColumns(setOpenDialog);

  const columns: GridColDef[] = UnapprovedMovementColumns(
    movementReasons.map((reason) => reason.code),
    warehouses.map((warehouse) => warehouse.code),
    setMovementToDelete
  );

  const approveSelectedMovements = () => {
    if (!checkSelectedMovements()) return;

    const movementsToApprove = Object.values(selectedMovements.current)
      .flat()
      .map((id) => +id);
    unapprovedMovementService.approveSelected(movementsToApprove).then((res) => {
      if (res['error']) {
        toast.error(res['error']);
      }
      if (res['warnings']) {
        res['warnings'].forEach((warning) => {
          toast.warning(warning.message);
        });
      }
      if (res['details']) {
        toast.success(res['details']);
      }
      selectedMovements.current = {};
      if (datagridRefresh?.current) datagridRefresh.current();
    });
  };

  const deleteSelectedMovements = () => {
    if (!checkSelectedMovements()) return;

    const movementsToDelete = Object.values(selectedMovements.current)
      .flat()
      .map((id) => +id);
    unapprovedMovementService.deleteSelected(movementsToDelete).then(() => {
      selectedMovements.current = {};
      if (datagridRefresh?.current) datagridRefresh.current();
    });
  };

  function checkSelectedMovements() {
    if (Object.values(selectedMovements.current).flat().length === 0) {
      alert('Nessun movimento selezionato');
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (movementToDelete) {
      setOpenConfirmationDialog(true);
    }
  }, [movementToDelete]);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setMovementToDelete(null);
  };

  const deleteMovement = () => {
    movementService.delete(movementToDelete).then(() => {
      toast.success('Movimento eliminato con successo.');
      setOpenConfirmationDialog(false);
      setMovementToDelete(null);
      if (datagridRefresh?.current) datagridRefresh.current();
    });
  };

  const setSelectedMovementsCallback = (key, value) => {
    selectedMovements.current = {
      ...selectedMovements.current,
      [key]: value
    };
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <DetailPanelContent
        uuid={row.id}
        service={unapprovedMovementService}
        row={{ ...row, noDDT }}
        columns={columns}
        setSelectedMovements={setSelectedMovementsCallback}
      />
    ),
    [columns]
  );

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ButtonGroup size="small">
            <Button onClick={approveSelectedMovements}>
              {t(`${translationPrefix}.bulk-approve`)}
            </Button>
            <Button color="error" onClick={deleteSelectedMovements}>
              {t(`${translationPrefix}.bulk-delete`)}
            </Button>
          </ButtonGroup>
        </div>
      }>
      <EnhancedDataGrid
        columns={aggregatedColumns}
        service={unapprovedMovementService}
        getMethod={unapprovedMovementService.aggregated}
        outerParams={noDDT ? { noDDT } : { transport_document_code: transportDocument }}
        refresh={datagridRefresh}
        getDetailPanelContent={getDetailPanelContent}
      />
      <div>
        {/* Confirmation dialog PRE-ConfirmationButtonDelete */}
        <Dialog
          open={openConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          fullWidth
          maxWidth="md">
          <DialogTitle id="alert-dialog-title">
            {t(`pages.movements.delete-confirmation`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{t(`pages.movements.delete-confirmation-extended`)}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-between">
              <Button onClick={handleCloseConfirmationDialog}>{t('actions.cancel')}</Button>
              <div>
                <Button variant="contained" color="error" onClick={deleteMovement} autoFocus>
                  {t('actions.delete')}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="md">
          <DialogTitle id="alert-dialog-title">{t(`pages.movements.edit`)}</DialogTitle>
          <DialogContent>
            <EditUnapprovedMovement
              identificators={identificators}
              pwc={pwc}
              onSubmit={onSubmitEdit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="error">
              {t('actions.cancel')}
            </Button>
            <Button type="submit" form="updateMovement">
              {t(`actions.confirm`)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </TenantPage>
  );
};

export default UnapprovedMovements;
