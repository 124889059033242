import {
  contractLineService,
  movementReasonService,
  orderLineService,
  orderService,
  productService,
  stockQuantityService,
  tenantSettingsService,
  warehouseService
} from 'services/api';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { IdentityField } from 'components/form/IdentityField';
import { IContractLine, IOption, ITenantSettings } from 'interfaces';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFinalForm from 'components/form/ReactFinalForm';
import dayjs from 'dayjs';
import { Alert } from '@mui/material';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';

interface ManualMovementProps {
  onSubmit: any;
}

function ManualMovement(props: ManualMovementProps) {
  const { onSubmit } = props;
  const { t } = useTranslation();

  const defaultWarehouse = null;
  // const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);
  // useEffect(() => {
  //   setWarehouse({ code: defaultWarehouse, id: 0 });
  // }, [defaultWarehouse]);

  const [products, setProducts] = useState<IOption[]>([]);
  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [contractLines, setContractLines] = useState<IContractLine[]>([]);
  const [warehouse, setWarehouse] = useState<IOption | null>(null);
  const [product, setProduct] = useState<IOption | null>(null);
  const [type, setType] = useState<IOption | null>(null);
  const [batches, setBatches] = useState<IOption[]>([]);
  const [orders, setOrders] = useState<IOption[]>([]);
  const [order, setOrder] = useState<IOption | null>(null);
  const [tenantSettings, setTenantSettings] = useState<ITenantSettings>();

  const [expiration, setExpiration] = useState<Date>();

  // const [orderNotes, setOrderNotes] = useState('');
  const [purchaseReqNotes, setPurchaseReqNotes] = useState('');

  useEffect(() => {
    tenantSettingsService.get(1).then(setTenantSettings);
    warehouseService.getAllBaseInformation().then(setWarehouses);
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
    productService.getAllBaseInformation().then(setProducts);
    orderService
      .openOrders(1000, 0, {
        warehouse: warehouse?.code ?? '',
        status: 1
      })
      .then((res) => {
        setOrders(
          res.results.map((value) => {
            return {
              id: value.id,
              code: value.ref,
              description: value.description
            };
          })
        );
      });
  }, []);

  useEffect(() => {
    if (warehouse?.id) {
      productService.getAllBaseInformation({ warehouse_id: warehouse.id }).then(setProducts);
    }
    if (type && type['direction'] === 'OUT' && product?.id && warehouse?.id) {
      const params = {
        product__id: product?.id ?? '',
        warehouse: warehouse?.code ?? '',
        quantity__gt: 0
      };
      stockQuantityService.getUnloadStocks(1000, 0, params).then((res) => {
        setBatches(
          res.results.map((value) => {
            let description = value.product_batch;
            if (value.expiration_date) description += `; Scad: ${value.expiration_date}`;
            description += `; Qty: ${value.total_quantity}`;
            return {
              id: value.product_batch,
              code: description,
              expiration_date: value.expiration_date
            };
          })
        );
      });
    } else {
      setBatches([]);
    }
    if (type?.id && type['direction'] === 'IN') {
      const params = {
        warehouse: warehouse?.code ?? '',
        status: 1
      };
      orderService.openOrders(1000, 0, params).then((res) => {
        setOrders(
          res.results.map((value) => {
            return {
              id: value.id,
              code: value.ref,
              description: value.description
            };
          })
        );
      });
    } else {
      setOrders([]);
    }

    if (product && warehouse) {
      const params = {
        product_id: product?.id ?? '',
        warehouse: warehouse['id'] ?? ''
      };
      contractLineService.getAllBaseInformation(params).then((res) => {
        setContractLines(
          res.map((contractLine) => {
            return {
              id: contractLine.id,
              code: `${contractLine['code']} - ${contractLine['supplier']}`
            };
          })
        );
      });
    }
    // if (product) {
    //   setOrderNotes(product['order_notes']);
    //   warehouseService.getAllBaseInformation({ products: product.id }).then(setWarehouses);
    // } else {
    //   setOrderNotes('');
    // }
    if (order?.id && product?.id && warehouse?.id) {
      orderLineService
        .getAllPaginated(1, 0, {
          ref: order.code,
          product: product.code,
          warehouse: warehouse.code,
          status: 'PENDING'
        })
        .then((res) => {
          if (res?.results) setPurchaseReqNotes(res.results[0]?.notes);
          else setPurchaseReqNotes('');
        });
    } else {
      setPurchaseReqNotes('');
    }
  }, [type?.id, product?.id, warehouse?.id, order?.id]);

  const submitForm = (values) => {
    productService.get(product.id).then((res) => {
      const movement = { ...values, description: res.description };
      if (warehouse && warehouse.code) {
        movement.warehouse = warehouse.code;
      }
      if (contractLines.length === 1) {
        movement.unit_price = contractLines[0].unit_price;
        movement.contract_line = contractLines[0].id;
      }
      const results = {
        movement: movement,
        batches: batches
      };
      onSubmit(results);
    });
  };

  return (
    <ReactFinalForm onSubmit={submitForm}>
      <div className="flex flex-wrap my-4">
        {tenantSettings &&
          expiration &&
          tenantSettings.days_before_expiration_alert &&
          dayjs(expiration).diff(dayjs(), 'days') <=
            tenantSettings.days_before_expiration_alert && (
            <Alert severity="error" className="w-full">
              {t(`alerts.product-close-to-expiration`)}
            </Alert>
          )}
        {purchaseReqNotes && (
          <Alert severity="info" className="w-full mb-2">
            {purchaseReqNotes}
          </Alert>
        )}
        {!defaultWarehouse && (
          <div className="w-full my-2">
            <IdentityField
              name="warehouse"
              component={AutocompleteAdapter}
              options={warehouses}
              label={t('global.warehouse')}
              type="text"
              optionValue={'code'}
              setValue={setWarehouse}
              validate={required}
            />
          </div>
        )}
        {type && type['direction'] === 'IN' ? (
          <>
            <div className="w-full my-2">
              <IdentityField
                name="order_code"
                component={AutocompleteAdapter}
                options={orders}
                label={t('entities.movement.order_code')}
                type="text"
                optionValue={'code'}
                optionLabel={'description'}
                setValue={setOrder}
                autoSelect={false}
              />
            </div>
          </>
        ) : null}
        <div className="w-full my-2">
          <IdentityField
            name="product"
            label={t('global.product')}
            component={AutocompleteAdapter}
            options={products}
            type="text"
            optionValue={'code'}
            optionLabel={'description'}
            setValue={setProduct}
            validate={required}
          />
        </div>
        <div className="w-full md:w-1/2 my-2 md:pr-2">
          <IdentityField
            name="movement_type"
            label={t('entities.movement.type')}
            component={AutocompleteAdapter}
            options={movementReasons}
            optionLabel="description"
            optionValue="code"
            type="text"
            setValue={setType}
            validate={required}
          />
        </div>
        <div className="w-full md:w-1/2 my-2 md:pl-2">
          <IdentityField
            name="moved_quantity"
            label={t('entities.movement.moved_quantity')}
            component={TextFieldAdapter}
            type="number"
            validate={required}
          />
        </div>
        {contractLines?.length !== 1 && (
          <div className="w-full my-2">
            <IdentityField
              name="contract_line"
              label={t('global.contract')}
              component={AutocompleteAdapter}
              options={contractLines}
              type="text"
              validate={required}
            />
          </div>
        )}
        {type && type['direction'] === 'OUT' ? (
          <div className="w-full">
            <IdentityField
              name="product_batch"
              label={t('entities.movement.product_batch')}
              component={AutocompleteAdapter}
              options={batches}
              type="text"
              validate={required}
              required
            />
          </div>
        ) : (
          <div className="w-full md:w-1/2 my-2 md:pr-2 mt-2">
            <IdentityField
              name="product_batch"
              label={t('entities.movement.product_batch')}
              component={TextFieldAdapter}
              type="text"
            />
          </div>
        )}
        {type && type['direction'] === 'IN' ? (
          <>
            <div className="w-full md:w-1/2 my-2 md:pl-2">
              <IdentityField
                name="expiration_date"
                component={DatePickerAdapter}
                label={t('entities.movement.expiration_date')}
                setValue={setExpiration}
                type="date"
              />
            </div>
            <div className="w-full md:w-1/2 my-2 md:pr-2">
              <IdentityField
                name="transport_document"
                component={TextFieldAdapter}
                label={t('entities.movement.transport_document')}
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 my-2 md:pl-2">
              <IdentityField
                name="transport_document_date"
                component={DatePickerAdapter}
                label={t('entities.movement.transport_document_date')}
                type="date"
                noFuture={true}
              />
            </div>
          </>
        ) : null}
        <div className="w-full my-2">
          <IdentityField
            name="notes"
            component={TextFieldAdapter}
            label={t('global.notes')}
            type="text"
          />
        </div>
        <div className="w-full my-2">
          <IdentityField
            name="print_labels"
            component={CheckboxAdapter}
            label={t(`pages.movements.print_labels`)}
            type="checkbox"
          />
        </div>
        <div className="w-full my-2">
          <IdentityField
            name="print_barcodes"
            component={CheckboxAdapter}
            label={t(`pages.movements.print_barcodes`)}
            type="checkbox"
          />
        </div>
      </div>
    </ReactFinalForm>
  );
}

export default ManualMovement;
