import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  IconButton
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

interface ConfirmationButtonDialogProps {
  id?: number;
  iconButton?: boolean;
  message?: string;
  handleDelete?: (id: number) => void;
}

export default function ConfirmationButtonDialog(params: ConfirmationButtonDialogProps) {
  const { id, iconButton, message, handleDelete } = params;
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  /**
   * Opens the confirmation dialog for deleting a row.
   *
   * @param {number} id - The ID of the row to be deleted.
   */
  const handleOpenDialog = () => {
    // Set the selected row ID and open the dialog.
    setOpenDialog(true);
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleCloseDialog = () => {
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(id ? id : null);

    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  return (
    <>
      {iconButton ? (
        <IconButton onClick={handleOpenDialog} color="error">
          <DeleteIcon />
        </IconButton>
      ) : (
        <Button onClick={handleOpenDialog} color="error">
          <DeleteIcon /> {t('actions.delete')}
        </Button>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t('actions.confirmation.delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message ? message : ''}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            {t('actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
